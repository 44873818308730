import type { RefCallback } from 'preact'
import { useState, useEffect } from 'preact/hooks'

import { SECONDS } from '../constants/DateTime.ts'

/**
 * Hide cursor by default, show on move
 * Note: Sometimes Doesn't hide (toggle) cursor when DevTools pane is opened
 * @link https://legacy.reactjs.org/docs/hooks-faq.html#how-can-i-measure-a-dom-node
 */
export const useCursorShow = (delay: number = 5 * SECONDS): [boolean, RefCallback<HTMLElement>] => {
  const [showCursor, setShowCursor] = useState<boolean>(false)
  const [element, setElement] = useState<HTMLElement | null>(null)

  useEffect(() => {
    if (!element) {
      return
    }

    let timeoutId: number | undefined

    const [handleMouseMoveThrottled] = throttle(handleMouseMove, 1 * SECONDS)

    element.addEventListener('mousemove', handleMouseMoveThrottled)

    return () => {
      element.removeEventListener('mousemove', handleMouseMoveThrottled)
      window.clearTimeout(timeoutId)
    }

    function handleMouseMove() {
      setShowCursor(true)

      window.clearTimeout(timeoutId)
      timeoutId = window.setTimeout(() => setShowCursor(false), delay)
    }
  }, [element, delay])

  return [showCursor, setElement]
}

/**
 * Throttle function by given delay
 * [throttle-ts]{@link https://github.com/martinstark/throttle-ts/tree/main}
 */
function throttle<Return, Args extends unknown[]>(fn: (...args: Args) => Return, delay: number): [(...args: Args) => Return | void, () => void] {
  let wait = false
  let timeout: number | undefined
  let cancelled = false

  return [
    (...args: Args) => {
      if (cancelled) return
      if (wait) return

      const value = fn(...args)

      wait = true

      timeout = window.setTimeout(() => {
        wait = false
      }, delay)

      return value
    },
    () => {
      cancelled = true
      window.clearTimeout(timeout)
    },
  ]
}
