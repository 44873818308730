import type { ComponentType, FunctionComponent } from 'preact'
import { useEffect, useMemo, useState } from 'preact/hooks'

import type { ItemContentProps } from '../../Inactivity.tsx'
import type { GlobalScoresAggregateStat, GlobalScoresAggregateStats } from './types.d.ts'
import { useSystemStore } from '../../../../store/systemStore.ts'

import { MINUTES } from '../../../../constants/DateTime.ts'
import { getMonthDateRange } from '../../../../utils/date.ts'

import './GameScoresAggregate.css'

type ProcessedGlobalScoresAggregateStat = GlobalScoresAggregateStat & { position: number }
type ProcessedGlobalScoresAggregateStats = ProcessedGlobalScoresAggregateStat[] | null

type GameScoresAggregateProps = {
  filters: {
    sex?: 'M' | 'F'
    ageCategory?: 'Youth' | 'Junior' | 'Open'
    weightCategory?: string
  }
  subCaption?: string
  groupByTerminalCity?: boolean
}

type ComponentParams = ItemContentProps & GameScoresAggregateProps

/**
 * Global scores content (max | avg)
 * Use instead of deprecated Global Scores component
 */
export const GlobalScoresAggregate: FunctionComponent<ComponentParams> = ({ isCurrent, filters, subCaption = undefined, groupByTerminalCity = false }) => {
  const accessToken = useSystemStore((state) => state.accessToken)

  const [globalScoresStats, setGlobalScoresStats] = useState<GlobalScoresAggregateStats | null>(null)
  const [fetchDateTs, setFetchDateTs] = useState<number | null>(null)

  // Sort by position
  const processedGlobalScoreStats: ProcessedGlobalScoresAggregateStats = useMemo(
    () =>
      globalScoresStats?.data
        // Clone
        .slice()
        // Sort by value
        .sort((statA, statB) => statB.value - statA.value || statA.player.nickName.localeCompare(statB.player.nickName))
        // Add position
        .map((stat, index) => ({ ...stat, position: index })) ?? null,
    [globalScoresStats]
  )

  // Fetch
  useEffect(() => {
    if (!accessToken || !isCurrent) {
      return
    }

    const now = new Date()

    // Cache not stale
    if (fetchDateTs && fetchDateTs + 5 * MINUTES > now.getTime()) {
      return
    }

    const monthDateRange = getMonthDateRange(now)

    const url = new URL('/game/scores/aggregate', import.meta.env.VITE_API_URL)

    // Current month
    url.searchParams.set('startDate', monthDateRange.startDate.toISOString())
    url.searchParams.set('endDate', monthDateRange.endDate.toISOString())

    // Aggregate function
    url.searchParams.set('fn', 'max')

    // Group by
    if (groupByTerminalCity) {
      url.searchParams.set('groupBy', 'terminal.city.name')
    }

    // Add filters
    for (const [key, value] of Object.entries(filters)) {
      url.searchParams.set(key, value)
    }

    // Pagination
    url.searchParams.set('offset', '0')
    url.searchParams.set('limit', '15')

    const abortController = new AbortController()

    fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      signal: abortController.signal,
    })
      .then((response) => {
        response.ok &&
          response.json().then((globalScoresState: GlobalScoresAggregateStats) => {
            setGlobalScoresStats(globalScoresState)
            setFetchDateTs(Date.now())
          })
      })
      .catch(() => {})

    return () => abortController.abort('unmount')
  }, [accessToken, filters, groupByTerminalCity, isCurrent, fetchDateTs])

  return (
    <table className="bx-global-scores-aggregate-table">
      <caption className="bx-global-scores-aggregate-table__caption">
        {'Ranking miesiąca'}
        {subCaption && <small className="bx-global-scores-aggregate-table__subcaption">{subCaption}</small>}
      </caption>
      {/** Show head when data is available */}
      {processedGlobalScoreStats && processedGlobalScoreStats.length > 0 && (
        <thead>
          <tr className="bx-global-scores-aggregate-table__row">
            <th>
              <span>{'Miejsce'}</span>
            </th>
            <th>
              <span>{'Ksywka'}</span>
            </th>
            <th>
              <span>{groupByTerminalCity ? 'Miasto' : 'Max'}</span>
            </th>
          </tr>
        </thead>
      )}
      {processedGlobalScoreStats ? (
        // Data
        <tbody className="bx-global-scores-aggregate-table__body">
          {processedGlobalScoreStats.map((scoreStat, index, scoreStats) => (
            <tr key={index} className="bx-global-scores-aggregate-table__row">
              <td className="bx-global-scores-aggregate-table__position">
                {index && scoreStats.at(index - 1)?.position === scoreStat.position ? null : scoreStat.position + 1}
              </td>
              <td className="bx-global-scores-aggregate-table__nickname">{scoreStat.player.nickName}</td>
              <td className="bx-global-scores-aggregate-table__data">{groupByTerminalCity ? scoreStat.groupByValue : scoreStat.value}</td>
            </tr>
          ))}
        </tbody>
      ) : (
        // Loading indicator
        <tbody className="bx-global-scores-aggregate-table__message-container">
          <tr>
            <td>
              <p>{'Wczytywanie danych…'}</p>
            </td>
          </tr>
        </tbody>
      )}
      {/** Low item count indicator */}
      {processedGlobalScoreStats && processedGlobalScoreStats.length < 10 && (
        <tfoot className="bx-global-scores-aggregate-table__message-foot bx-global-scores-aggregate-table__message-container">
          <tr>
            <td colSpan={3}>
              <p className="bx-text-uppercase">{'nowy miesiąc = nowy ranking'}</p>
              <p>{'zagraj, żeby zająć miejsce w rankingu'}</p>
            </td>
          </tr>
        </tfoot>
      )}
    </table>
  )
}

/**
 * Create GloabScoresAggregate component with given props
 */
export function withGameScoresAggregateProps<P extends ItemContentProps>(
  WrappedComponent: ComponentType<ComponentParams>,
  gameScoresAggregateProps: GameScoresAggregateProps
): ComponentType<P> {
  const Wrapped: ComponentType<P> = (props) => <GlobalScoresAggregate {...props} {...gameScoresAggregateProps} />

  Wrapped.displayName = `withGameScoresProps(${
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    WrappedComponent.displayName ?? WrappedComponent.name ?? 'Component'
  })`

  return Wrapped
}
