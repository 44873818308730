import type { FunctionComponent } from 'preact'
import { useEffect, useState } from 'preact/hooks'

import { SECONDS } from '../../constants/DateTime.ts'
import { GameIdleVideo } from '../../components/GameIdleVideo/GameIdleVideo.tsx'
import { GamePanels } from '../../components/GamePanels/GamePanels.tsx'

import './GameReady.css'

export const GameReady: FunctionComponent = () => {
  const [isIdle, setIsIdle] = useState<boolean>(false)

  useEffect(() => {
    const timer = window.setTimeout(() => setIsIdle(true), 10 * SECONDS)

    return () => window.clearTimeout(timer)
  }, [])

  if (isIdle) {
    return <GameIdleVideo />
  }

  return (
    <section className="bx-page bx-page--game-ready">
      <h2 className="bx-animation bx-animation--pop-up bx-action-heading bx-action-heading--large">{'Hit!'}</h2>

      <GamePanels />
    </section>
  )
}
