import type { FunctionComponent } from 'preact'
import { useEffect, useRef } from 'preact/hooks'
import clsx from 'clsx'
import canvasConfetti, { type Options } from 'canvas-confetti'

import { SECONDS } from '../../constants/DateTime.ts'

import './ConfettiOverlay.css'

const defaultOptions: Options = {
  spread: 120,
  startVelocity: 90,
  gravity: 4,
  ticks: 300,
  colors: ['#fff4a6', '#f7de7c', '#e0bf61', '#b99335', '#b27f2c'],
  shapes: ['square'],
  scalar: 3,
}

/**
 * Confetti overlay
 * @link https://www.npmjs.com/package/canvas-confetti
 */
export const ConfettiOverlay: FunctionComponent<{ className?: string; particleCount?: number; iterationsCount?: number }> = ({
  className,
  particleCount = 200,
  iterationsCount = 3,
}) => {
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const particleCountRef = useRef<number>(particleCount)
  const iterationsCountRef = useRef<number>(iterationsCount)

  useEffect(() => {
    if (!canvasRef.current) {
      return
    }

    const confetti = canvasConfetti.create(canvasRef.current, {
      // Note: This allows library to manipulate canvas element width and height properties
      resize: true,
    })

    const fire = (particleRatio: number, options?: Options) =>
      confetti({
        ...defaultOptions,
        ...options,
        particleCount: Math.floor(particleCountRef.current * particleRatio),
        origin: { x: getRandomValueBetween(0.4, 0.6), y: getRandomValueBetween(0.15, 0.35) },
      })

    // Schedule iterations one after another
    let iteration = 0

    const interval = window.setInterval((): void => {
      if (++iteration > iterationsCountRef.current) {
        return window.clearInterval(interval)
      } else if (document.hidden) {
        return
      }

      fire(0.4, { scalar: 3, startVelocity: 90, gravity: 4 })
      fire(0.6, { scalar: 1, startVelocity: 60, gravity: 3 })
    }, 0.5 * SECONDS)

    return () => {
      window.clearInterval(interval)

      confetti.reset()
    }
  }, [])

  return <canvas ref={canvasRef} className={clsx(['bx-confetti-overlay', className])}></canvas>
}

/**
 * Get random value between min and max
 * @link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/random
 * @throws {RangeError}
 */
function getRandomValueBetween(min: number, max: number): number {
  if (min > max) {
    throw new RangeError('Min argument must lower than min argument')
  }

  return Math.random() * (max - min) + min
}
