import type { FunctionComponent } from 'preact'
import Router, { type RouterOnChangeArgs, getCurrentUrl, exec, route } from 'preact-router'

import { useCallback, useState, useEffect, useRef } from 'preact/hooks'
import { useShallow } from 'zustand/react/shallow'
import clsx from 'clsx'

import type { EventSourceMessageTypes } from './interfaces/EventSourceMessage.d.ts'
import { useCursorShow } from './hooks/useCursorShow.ts'
import { useWakeLock } from './hooks/useWakeLock.ts'
import { useIsPageStatePassive } from './hooks/useIsPageStatePassive.ts'
import { UpdateHandler } from './components/UpdateHandler/UpdateHandler.tsx'
import { fetchEventSourceHead } from './utils/eventSource.ts'
import { AppInfoPanel } from './components/AppInfoPanel/AppInfoPanel.tsx'
import { AudioMixerProvider, useAudioMixer } from './components/AudioMixer/AudioMixer.tsx'
import { VideoProvider } from './components/VideoProvider/VideoProvider.tsx'
import { BackgroundVideo } from './components/BackgroundVideo/BackgroundVideo.tsx'
import { ErrorBoundary } from './components/ErrorBoundary/ErrorBoundary.tsx'
import { SystemStateProvider } from './components/SystemStateProvider/SystemStateProvider.js'
import { useGameStore } from './store/gameStore.ts'
import { useSystemStore } from './store/systemStore.ts'
import { SECONDS } from './constants/DateTime.ts'

import { Init } from './pages/Init/Init.tsx'
import { Inactivity } from './pages/Inactivity/Inactivity.tsx'
import { SessionStart } from './pages/SessionStart/SessionStart.tsx'
import { GameStart } from './pages/GameStart/GameStart.tsx'
import { GameReady } from './pages/GameReady/GameReady.tsx'
import { GameScores } from './pages/GameScores/GameScores.tsx'
import { SessionEnd } from './pages/SessionEnd/SessionEnd.tsx'
import { InfoNoCredits } from './pages/InfoNoCredits/InfoNoCredits.tsx'
import { Message } from './pages/Message/Message.tsx'
import { Configuration } from './pages/Configuration/Configuration.tsx'
import { SignIn } from './pages/Sign-In/Sign-In.tsx'

import IconImage from './assets/images/icon.svg?react'

import { AUDIO } from './constants/Audio.ts'

import './App.css'

const App: FunctionComponent = () => {
  const playAudio = useAudioMixer()

  const [eventSource, setEventSource] = useState<EventSource | null>(null)
  const [eventSourceReadyState, setEventSourceReadyState] = useState<number | null>(null)

  const serialNumber = useSystemStore((state) => state.serialNumber)
  const accessToken = useSystemStore((state) => state.accessToken)
  const setCurrentPage = useSystemStore((state) => state.setCurrentPage)

  const [setSessionToken, setPlayer, fetchPlayer, fetchPlayerScoresStats, setCredits, setGameScore, setIsGameScoreDisplayed, setEndBoxingResult, resetGame] =
    useGameStore(
      useShallow((state) => [
        state.setSessionToken,
        state.setPlayer,
        state.fetchPlayer,
        state.fetchPlayerScoresStats,
        state.setCredits,
        state.setGameScore,
        state.setIsGameScoreDisplayed,
        state.setEndBoxingResult,
        state.resetGame,
      ])
    )

  const isMachinePairedRef = useRef<boolean | null>(null)

  // TODO: Move to store after events refactor
  const lastMessageEventRef = useRef<MessageEvent>()

  const sessionEndTimeoutId = useRef<number | null>(null)

  // Create new event source
  const createEventSource: (url: string | URL) => Promise<void> = useCallback(async (url) => {
    // Clone
    url = new URL(url)

    // Append lastEventId to url query
    if (lastMessageEventRef.current) {
      url.searchParams.set('last-event-id', lastMessageEventRef.current.lastEventId)
    }

    // Test
    const response = await fetchEventSourceHead(url)

    // Client error (non-retriable)
    if (response && response.status >= 400 && response.status < 500 && response.status !== 429) {
      return setEventSourceReadyState(EventSource.CLOSED)
    }

    setEventSource(new EventSource(url, { withCredentials: true }))
  }, [])

  // Create new event source
  useEffect(() => {
    if (!serialNumber) {
      return
    }

    const url = new URL(`terminal/${serialNumber}/events`, import.meta.env.VITE_API_URL)

    accessToken && url.searchParams.set('access-token', accessToken)
    url.searchParams.set('ts', Date.now().toFixed())

    createEventSource(url)
  }, [serialNumber, accessToken, createEventSource])

  // Handle event source events
  useEffect(() => {
    if (!eventSource) {
      return
    }

    let reconnectOnErrorTimeoutId: number | undefined = undefined

    // Connecting
    setEventSourceReadyState(eventSource.readyState)

    // Handle open
    eventSource.addEventListener('open', (_openEvent) => {
      setEventSourceReadyState(eventSource.readyState)
    })

    // Handle network (connecting) or server error (closed)
    eventSource.addEventListener('error', (_errorEvent) => {
      // Because we'll try to reconnect
      setEventSourceReadyState(EventSource.CONNECTING)

      // Reconnection on network error, no-op
      if (eventSource.readyState === EventSource.CONNECTING) {
        return
      }

      /**
       * Fatal error
       * - Non-200 response status
       * - request blocked by CORS policy
       * - network request timeout (90s+)
       * - system/shutdown (check lastMessageEventRef.current?.type)
       */
      reconnectOnErrorTimeoutId = window.setTimeout(() => void createEventSource(eventSource.url), 15 * SECONDS)
    })

    // Generic event without a type
    eventSource.addEventListener('message', (messageEvent: MessageEvent<string>) => (lastMessageEventRef.current = messageEvent))

    // System shutdown, handled by error listener
    eventSource.addEventListener('system/shutdown', (messageEvent: MessageEvent<string>) => (lastMessageEventRef.current = messageEvent))

    // Stream
    eventSource.addEventListener('stream/start', (messageEvent: MessageEvent<string>) => (lastMessageEventRef.current = messageEvent))
    eventSource.addEventListener('stream/ping', (messageEvent: MessageEvent<string>) => (lastMessageEventRef.current = messageEvent))

    // Credits top-up event
    eventSource.addEventListener('game/credits', (messageEvent: MessageEvent<string>) => {
      lastMessageEventRef.current = messageEvent

      const data = JSON.parse(messageEvent.data) as EventSourceMessageTypes['game/credits']
      setCredits(data)
      playAudio(AUDIO.EVENT_GAME_CREDITS)
    })

    // Session start event (no sound, there is one for game/credits)
    eventSource.addEventListener('session/start', (messageEvent: MessageEvent<string>) => {
      lastMessageEventRef.current = messageEvent

      const data = JSON.parse(messageEvent.data) as EventSourceMessageTypes['session/start']

      setSessionToken(data.token)

      setPlayer(null)
      setGameScore(null)
      setEndBoxingResult(null)

      route('/session/start')
    })

    // Player sign-in event
    eventSource.addEventListener('player/sign-in', (messageEvent: MessageEvent<string>) => {
      lastMessageEventRef.current = messageEvent

      const data = JSON.parse(messageEvent.data) as EventSourceMessageTypes['player/sign-in']

      playAudio(AUDIO.EVENT_PLAYER_SIGN_IN)

      try {
        fetchPlayer(data.playerId, accessToken)
        fetchPlayerScoresStats(data.playerId, accessToken)
      } catch {
        // Continue regardless of error
      }
    })

    // Player sign-out event (no sound, there is one for game/scores)
    eventSource.addEventListener('player/sign-out', (messageEvent: MessageEvent<string>) => {
      lastMessageEventRef.current = messageEvent

      setPlayer(null)
    })

    // Game Start event
    eventSource.addEventListener('game/start', (messageEvent: MessageEvent<string>) => {
      lastMessageEventRef.current = messageEvent

      // Reset score displayed
      // Note: Also reset on LED screen ~2min after game/scores event
      setIsGameScoreDisplayed(false)

      playAudio(AUDIO.EVENT_GAME_START)

      route('/game/start')
    })

    // Game Ready event
    eventSource.addEventListener('game/ready', (messageEvent: MessageEvent<string>) => {
      lastMessageEventRef.current = messageEvent

      playAudio(AUDIO.EVENT_GAME_READY)

      route('/game/ready')
    })

    // Game Scores event
    eventSource.addEventListener('game/scores', (messageEvent: MessageEvent<string>) => {
      lastMessageEventRef.current = messageEvent

      const data = JSON.parse(messageEvent.data) as EventSourceMessageTypes['game/scores']

      setGameScore(data.value)
      setCredits(data.credits)

      playAudio(AUDIO.EVENT_GAME_SCORES)

      route('/game/scores')
    })

    // Game Scores Displayed event
    eventSource.addEventListener('game/scores-displayed', (messageEvent: MessageEvent<string>) => {
      lastMessageEventRef.current = messageEvent

      setIsGameScoreDisplayed(true)
    })

    // Session end event
    eventSource.addEventListener('session/end', (messageEvent: MessageEvent<string>) => {
      lastMessageEventRef.current = messageEvent

      const data = JSON.parse(messageEvent.data) as EventSourceMessageTypes['session/end']

      setEndBoxingResult(data)
      // Show last score before summary
      sessionEndTimeoutId.current = window.setTimeout(() => {
        setGameScore(null)
        playAudio(AUDIO.EVENT_GAME_CREDITS)
        route('/session/end')
      }, 10 * SECONDS)
    })

    // Machine paired event
    eventSource.addEventListener('machine/paired', (messageEvent: MessageEvent<string>) => {
      lastMessageEventRef.current = messageEvent

      const data = JSON.parse(messageEvent.data) as EventSourceMessageTypes['machine/paired']

      // Redirect only on state change (instead of on each event)
      if (isMachinePairedRef.current !== data) {
        route(data ? '/inactivity' : '/configuration')
      }

      isMachinePairedRef.current = data
    })

    // Info: No credits event
    eventSource.addEventListener('info/no-credits', (messageEvent: MessageEvent<string>) => {
      lastMessageEventRef.current = messageEvent

      route('/info/no-credits')
    })

    // Reset current game
    eventSource.addEventListener('game/reset', (messageEvent: MessageEvent<string>) => {
      lastMessageEventRef.current = messageEvent

      resetGame()

      // Note: Should reset on Sign-in which should be a modal
      const resettableGameRoutes: string[] = ['/session/start', '/game/start', '/game/ready', '/game/scores', '/session/end', '/info/no-credits']
      const isGameRoute = resettableGameRoutes.some((resettableGameRoute) => exec(getCurrentUrl(), resettableGameRoute, {}))

      // Reset route when in game
      if (isGameRoute) {
        route('/inactivity')
      }
    })

    // Close event source for given serial number
    return () => {
      eventSource.close()
      window.clearTimeout(reconnectOnErrorTimeoutId)
    }
  }, [eventSource]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleRouteChange = (args: RouterOnChangeArgs) => {
    // Stop any redirections after a timeout
    sessionEndTimeoutId.current && window.clearTimeout(sessionEndTimeoutId.current)

    setCurrentPage(args.path === '/inactivity' ? 'inactivity' : null)
  }

  // Fatal error
  if (!serialNumber) {
    return <Message text={'Brakujący lub błędny parametr\n "serial-number" w adresie URL'} error />
  }

  // Connection states
  // Note: This prevents rendering Router component
  // TODO: Use modals
  switch (eventSourceReadyState) {
    case null:
      return <Init />

    case EventSource.CONNECTING:
      return <Message text="Trwa łączenie z serwerem API…" />

    case EventSource.CLOSED:
      return <Message text="Brak połączenia z serwerem API" error />

    case EventSource.OPEN:
    default:
      break
  }

  return (
    <Router onChange={handleRouteChange}>
      <Init path="/" />
      <Configuration path="/configuration" />
      <SignIn path="/sign-in" />
      <Inactivity path="/inactivity" />
      <SessionStart path="/session/start" />
      <GameStart path="/game/start" />
      <GameReady path="/game/ready" />
      <GameScores path="/game/scores" />
      <SessionEnd path="/session/end" />
      <InfoNoCredits path="/info/no-credits" />
    </Router>
  )
}

/**
 * Wrap app component by providers
 */
const AppWrapper: FunctionComponent<{ startUrl: URL; env: ImportMetaEnv }> = ({ startUrl, env }) => {
  const [showCursor, setElementRef] = useCursorShow()
  const isPageStatePassive = useIsPageStatePassive()

  useWakeLock()

  return (
    <main ref={setElementRef} className={clsx('bx-app', { 'bx-app--show-cursor': showCursor })}>
      <ErrorBoundary>
        <SystemStateProvider startUrl={startUrl} fallback={<Message text="Wczytywanie stanu…" />}>
          <UpdateHandler startUrl={startUrl} disableServiceWorker={env.VITE_APP_DISABLE_SW === 'true'} restartPending={<Message text={'Aktualizacja…'} />}>
            <AudioMixerProvider fallback={<Message text="Ładowanie dźwięków…" />}>
              <VideoProvider fallback={<Message text="Ładowanie animacji…" />}>
                <App />
              </VideoProvider>
            </AudioMixerProvider>
          </UpdateHandler>
          <AppInfoPanel />
        </SystemStateProvider>
      </ErrorBoundary>
      <BackgroundVideo pause={isPageStatePassive || showCursor} />
      {/* Symbols  */}
      <svg className="bx-app__svg-symbols" xmlns="http://www.w3.org/2000/svg">
        <IconImage id="bx-icon-symbol" />
      </svg>
    </main>
  )
}

export { AppWrapper as App }
