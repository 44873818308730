import type { FunctionComponent } from 'preact'
import { useEffect, useState } from 'preact/hooks'

import { SECONDS } from '../../constants/DateTime.ts'
import { useGameStore } from '../../store/gameStore.ts'
import { GameVideo } from '../../components/GameVideo/GameVideo.tsx'
import { GameIdleVideo } from '../../components/GameIdleVideo/GameIdleVideo.tsx'
import { GamePanels } from '../../components/GamePanels/GamePanels.tsx'
import { useAudioMixer } from '../../components/AudioMixer/AudioMixer.tsx'
import { usePreloadedVideo } from '../../components/VideoProvider/VideoProvider.tsx'
import { ConfettiOverlay } from '../../components/ConfettiOverlay/ConfettiOverlay.tsx'

import { AUDIO } from '../../constants/Audio.ts'

/**
 * @example
 * Importing all videos at once
 * ```js
 * const videoSrcUrlMap = import.meta.glob('./videos/*.mp4', { eager: true, as: 'url' })
 * const url = videoSrcUrlMap['./videos/animacja_1_all-h.264.mp4']
 * ```
 */
import animacja_2_2 from './videos/animacja_2_2-h.264.mp4'
import animacja_4_2 from './videos/animacja_4_2-h.264.mp4'
import animacja_5_3 from './videos/animacja_5_3-h.264.mp4'
import animacja_1_mis_konfetti_2 from './videos/animacja_1_mis_konfetti_2-h.264.mp4'
import animacja_2_kangur_rewelacja_konfetti_2 from './videos/animacja_2_kangur_rewelacja_konfetti_2-h.264.mp4'
import animacja_7_mis_dobrze from './videos/animacja_7_mis_dobrze-h.264.mp4'
import animacja_8_mysz_rewelacja_konfetti_2 from './videos/animacja_8_mysz_rewelacja_konfetti_2-h.264.mp4'
import animacja_9_kangur_dobrze from './videos/animacja_9_kangur_dobrze-h.264.mp4'
import animacja_10_kangur_kiepsko from './videos/animacja_10_kangur_kiepsko-h.264.mp4'
import animacja_11_mis_srednio_2 from './videos/animacja_11_mis_srednio_2-h.264.mp4'
import animacja_12_mysz_srednio from './videos/animacja_12_mysz_srednio-h.264.mp4'
import animacja_13_mysz_kiepsko from './videos/animacja_13_mysz_kiepsko-h.264.mp4'

import './GameScores.css'

const MAX_HIT_POWER = {
  LIGHT: 400,
  MEDIUM: 600,
  HARD: 800,
}

/**
 * Show full video, then scores
 * Show idle video when page is shown longer than given threshold
 *
 * @note Max duration of scores animation on LED panel is 7s
 *       May use as a fall-back in case of missed 'game/scores-displayed' event
 */
export const GameScores: FunctionComponent = () => {
  // Note: Doesn't toggle for loops
  const [videoEnded, setVideoEnded] = useState<boolean>(false)
  const [isIdle, setIsIdle] = useState<boolean>(false)

  // Note: Should always be non-null
  const gameScore = useGameStore((state) => state.gameScore)
  const credits = useGameStore((state) => state.credits)
  const isGameScoreDisplayed = useGameStore((state) => state.isGameScoreDisplayed)
  const setIsGameScoreDisplayed = useGameStore((state) => state.setIsGameScoreDisplayed)
  const isGameScoreNewMax = useGameStore((state) => state.playerScoresIsNewMax)

  const playAudio = useAudioMixer()
  const getPreloadedVideo = usePreloadedVideo()

  const [videoSrc, setVideoSrc] = useState<string | null>(null)

  useEffect(() => {
    const videoBlob = gameScore ? getPreloadedVideo(getRandomItem(getVideoSrcs(gameScore))) : null
    const localVideoSrc = videoBlob && URL.createObjectURL(videoBlob)

    setVideoSrc(localVideoSrc)

    return () => localVideoSrc && URL.revokeObjectURL(localVideoSrc)
  }, [gameScore, getPreloadedVideo])

  // Set idle state
  useEffect(() => {
    if (!credits || !isGameScoreDisplayed) {
      return
    }

    const timer = window.setTimeout(() => setIsIdle(true), 10 * SECONDS)

    return () => window.clearTimeout(timer)
  }, [credits, isGameScoreDisplayed])

  // Manually set state when game/scores-displayed isn't received
  useEffect(() => {
    // Cancel timer
    if (!gameScore || isGameScoreDisplayed) {
      return
    }

    // Delay for max score 999 + some network delay (0.5s)
    // Should be 3s..7s depending on score; see mock ApiEvents
    const delay = 7.5 * SECONDS
    const timer = window.setTimeout(() => setIsGameScoreDisplayed(true), delay)

    return () => window.clearTimeout(timer)
  }, [gameScore, isGameScoreDisplayed, setIsGameScoreDisplayed])

  // Play audio when game score is displayed
  // Note: sounds are muted by board during score animation on LED panel
  useEffect(() => {
    if (!gameScore || !isGameScoreDisplayed) {
      return
    }

    if (gameScore <= MAX_HIT_POWER.LIGHT) {
      playAudio(AUDIO.PAGE_GAME_SCORES_DISPLAYED_400)
      return
    }

    if (gameScore <= MAX_HIT_POWER.MEDIUM) {
      playAudio(AUDIO.PAGE_GAME_SCORES_DISPLAYED_600)
      return
    }

    if (gameScore <= MAX_HIT_POWER.HARD) {
      playAudio(AUDIO.PAGE_GAME_SCORES_DISPLAYED_800)
      return
    }

    playAudio(AUDIO.PAGE_GAME_SCORES_DISPLAYED_999[0]).then((ts) =>
      ts?.addEventListener('ended', () => void playAudio(AUDIO.PAGE_GAME_SCORES_DISPLAYED_999[1]))
    )
  }, [gameScore, isGameScoreDisplayed, playAudio])

  // Idle animation
  if (isIdle) {
    return <GameIdleVideo />
  }

  // Score result animation
  if (videoSrc && (videoEnded === false || isGameScoreDisplayed === false)) {
    return <GameVideo src={videoSrc} onEnded={() => setVideoEnded(true)} onError={() => setVideoEnded(true)} />
  }

  return (
    <>
      <section className="bx-page bx-page--game-scores">
        <div>
          {isGameScoreNewMax ? (
            <h2 className="bx-action-heading bx-action-heading--small bx-animation bx-animation--slide-left">{'Twój nowy rekord'}</h2>
          ) : (
            <h2 className="bx-action-heading bx-action-heading--small bx-animation bx-animation--slide-left">{'Your score'}</h2>
          )}
          <h3 className="bx-action-heading bx-action-heading--large bx-animation bx-animation--slide-right">{gameScore ?? '—'}</h3>
        </div>

        <GamePanels />
      </section>
      {isGameScoreNewMax && <ConfettiOverlay className="bx-page bx-page--fullscreen" />}
    </>
  )
}

/**
 * Get random item from an array
 * @link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/random#getting_a_random_integer_between_two_values
 */
function getRandomItem<T>(items: T[]): T {
  const index = Math.floor(Math.random() * items.length)

  return items[index]
}

/**
 * Choose animations based on score
 * Boxer machine works with ranges: 1...400...800...999
 */
function getVideoSrcs(gameScore: number): string[] {
  if (gameScore >= MAX_HIT_POWER.HARD) {
    return [animacja_1_mis_konfetti_2, animacja_2_kangur_rewelacja_konfetti_2, animacja_8_mysz_rewelacja_konfetti_2]
  }

  if (gameScore >= MAX_HIT_POWER.MEDIUM) {
    return [animacja_2_2, animacja_7_mis_dobrze, animacja_9_kangur_dobrze]
  }

  if (gameScore >= MAX_HIT_POWER.LIGHT) {
    return [animacja_4_2, animacja_11_mis_srednio_2, animacja_12_mysz_srednio]
  }

  return [animacja_5_3, animacja_13_mysz_kiepsko, animacja_10_kangur_kiepsko]
}
