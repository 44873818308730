import type { FunctionComponent } from 'preact'

import { GamePanels } from '../../components/GamePanels/GamePanels.tsx'

import './GameStart.css'

export const GameStart: FunctionComponent = () => {
  return (
    <section className="bx-page bx-page--game-start">
      <h2 className="bx-action-heading bx-action-heading--small">{'Get ready…'}</h2>

      <GamePanels />
    </section>
  )
}
