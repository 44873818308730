import type { FunctionComponent } from 'preact'
import { useEffect, useMemo, useState } from 'preact/hooks'

import { GameVideo } from '../GameVideo/GameVideo.tsx'
import { useAudioMixer } from '../../components/AudioMixer/AudioMixer.tsx'
import { usePreloadedVideo } from '../VideoProvider/VideoProvider.tsx'

import { AUDIO } from '../../constants/Audio.ts'

import video6KangurSrc from './videos/animacja_6_kangur-h.264.mp4'
import video6MisSrc from './videos/animacja_6_mis-h.264.mp4'
import video6MyszSrc from './videos/animacja_6_mysz-h.264.mp4'

const idleVideos: string[] = [video6KangurSrc, video6MisSrc, video6MyszSrc]

export const GameIdleVideo: FunctionComponent = () => {
  const playAudio = useAudioMixer()
  const getPreloadedVideo = usePreloadedVideo()

  const videoBlobs: Blob[] = useMemo(
    () =>
      shuffleItems(idleVideos)
        .map((url) => getPreloadedVideo(url))
        .filter((blob): blob is Blob => blob !== null),
    [getPreloadedVideo]
  )

  const [videoSrc, setVideoSrc] = useState<string>()

  useEffect(() => {
    const audioItems = [AUDIO.PAGE_GAME_IDLE_1, AUDIO.PAGE_GAME_IDLE_2, AUDIO.PAGE_GAME_IDLE_3]
    const randomAudio = audioItems[Math.floor(Math.random() * audioItems.length)]

    playAudio(randomAudio[0]).then((ts) => ts?.addEventListener('ended', () => void playAudio(randomAudio[1])))
  }, [playAudio])

  useEffect(() => {
    const mediaSource = new MediaSource()
    const localVideoSrc = URL.createObjectURL(mediaSource)

    setVideoSrc(localVideoSrc)

    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    mediaSource.addEventListener('sourceopen', async () => {
      const sourceBuffer = mediaSource.addSourceBuffer('video/mp4; codecs="avc1.640028"')

      // Required for multiple sources
      sourceBuffer.mode = 'sequence'

      // Append in sequence
      for (const blob of videoBlobs) {
        const arrayBuffer = await blob.arrayBuffer()

        // Implementation of SourceBuffer.appendBufferAsync
        const appendBufferAbortController = new AbortController()

        await new Promise<Event>((resolve, reject) => {
          sourceBuffer.addEventListener('updateend', resolve, { signal: appendBufferAbortController.signal })
          sourceBuffer.addEventListener('error', reject, { signal: appendBufferAbortController.signal })
          sourceBuffer.appendBuffer(arrayBuffer)
        })

        appendBufferAbortController.abort()
      }

      // Signal the end of the stream
      mediaSource.endOfStream()
    })

    return () => URL.revokeObjectURL(localVideoSrc)
  }, [videoBlobs])

  return <GameVideo loop={true} src={videoSrc} />
}

/**
 * Shuffle array
 * @link https://stackoverflow.com/a/46545530/1012616
 */
function shuffleItems<T>(items: T[]): T[] {
  return items
    .map((value) => ({ value, sort: Math.random() }))
    .sort((a, b) => a.sort - b.sort)
    .map(({ value }) => value)
}
