import { useEffect, useState } from 'preact/hooks'

/**
 * Evaluate if page is in Passive state
 * @note The 'visibilitychange' event only reacts to: switching tabs, minimizing/ maximizing window
 *
 * [Web Platform > Page Lifecycle API]{@link https://developer.chrome.com/docs/web-platform/page-lifecycle-api}
 * [MDN > Page Visibility API]{@link https://developer.mozilla.org/en-US/docs/Web/API/Page_Visibility_API}
 */
export const useIsPageStatePassive = (): boolean => {
  const [pageHasFocus, setWindowHasFocus] = useState<boolean>(() => document.hasFocus())

  useEffect(() => {
    const eventAbortController = new AbortController()
    const eventListenerOptions: AddEventListenerOptions = {
      capture: true,
      signal: eventAbortController.signal,
    }

    // Switching windows, switching tabs, minimizing/ maximizing window
    window.addEventListener('focus', () => setWindowHasFocus(true), eventListenerOptions)
    window.addEventListener('blur', () => setWindowHasFocus(false), eventListenerOptions)

    return () => eventAbortController.abort()
  }, [])

  return pageHasFocus === false
}
