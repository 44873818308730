import type { ComponentType, FunctionComponent } from 'preact'
import { useEffect, useMemo, useState } from 'preact/hooks'

import type { ItemContentProps } from '../../Inactivity.tsx'
import type { GlobalScoresStat, GlobalScoresStats } from './types.d.ts'
import { useSystemStore } from '../../../../store/systemStore.ts'

import { MINUTES } from '../../../../constants/DateTime.ts'

import './GlobalScores.css'
import { getMonthDateRange } from '../../../../utils/date.ts'

type ProcessedGlobalScoresStat = GlobalScoresStat & { position: number }
type ProcessedGlobalScoresStats = ProcessedGlobalScoresStat[] | null

type GameScoresProps = {
  filters: {
    sex?: 'M' | 'F'
    ageCategory?: 'Youth' | 'Junior' | 'Open'
  }
  subCaption?: string
}

type ComponentParams = ItemContentProps & GameScoresProps

/**
 * Global scores (max + avg)
 * deprecated
 */
export const GlobalScores: FunctionComponent<ComponentParams> = ({ isCurrent, filters, subCaption = undefined }) => {
  const accessToken = useSystemStore((state) => state.accessToken)

  const [globalScoresStats, setGlobalScoresStats] = useState<GlobalScoresStats | null>(null)
  const [fetchDateTs, setFetchDateTs] = useState<number | null>(null)

  // Sort by position
  const processedGlobalScoreStats: ProcessedGlobalScoresStats = useMemo(
    () =>
      globalScoresStats
        // Clone
        ?.slice()
        // Sort by max
        .sort((statA, statB) => statB.max - statA.max || statA.player.nickName.localeCompare(statB.player.nickName))
        // Add position
        .map((stat, index) => ({ ...stat, position: index })) ?? null,
    /*
        // Add position with eqality
        .reduce<ProcessedGlobalScoresStat[]>((processedStats, stat) => {
          const prevStat = processedStats.at(-1)
          const position = !prevStat ? 0 : prevStat.max === stat.max ? prevStat.position : prevStat.position + 1

          return [...processedStats, { ...stat, position }]
        }, []) ?? null,
    */
    [globalScoresStats]
  )

  // Fetch
  useEffect(() => {
    if (!accessToken || !isCurrent) {
      return
    }

    const now = new Date()

    // Cache not stale
    if (fetchDateTs && fetchDateTs + 5 * MINUTES > now.getTime()) {
      return
    }

    const monthDateRange = getMonthDateRange(now)

    const url = new URL('/game/scores', import.meta.env.VITE_API_URL)

    // Current month
    url.searchParams.set('startDate', monthDateRange.startDate.toISOString())
    url.searchParams.set('endDate', monthDateRange.endDate.toISOString())

    // Add filters
    for (const [key, value] of Object.entries(filters)) {
      url.searchParams.set(key, value)
    }

    // Pagination (max 15 items fit layout)
    url.searchParams.set('offset', '0')
    url.searchParams.set('limit', '15')

    const abortController = new AbortController()

    fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      signal: abortController.signal,
    })
      .then((response) => {
        response.ok &&
          response.json().then((globalScoresStats: GlobalScoresStats) => {
            setGlobalScoresStats(globalScoresStats)
            setFetchDateTs(Date.now())
          })
      })
      .catch(() => {})

    return () => abortController.abort('unmount')
  }, [accessToken, filters, isCurrent, fetchDateTs])

  return (
    <table className="bx-global-scores-table">
      <caption className="bx-global-scores-table__caption">
        {'Ranking miesiąca'}
        {subCaption && <small>{subCaption}</small>}
      </caption>
      {/** Show head when data is available  */}
      {processedGlobalScoreStats && processedGlobalScoreStats?.length > 0 && (
        <thead className="bx-global-scores-table__head">
          <tr className="bx-global-scores-table__row">
            <th className="bx-global-scores-table__header">
              <span>{'Miejsce'}</span>
            </th>
            <th className="bx-global-scores-table__header">
              <span>{'Ksywka'}</span>
            </th>
            <th className="bx-global-scores-table__header">
              <span>{'Max'}</span>
            </th>
            <th className="bx-global-scores-table__header">
              <span>{'Średnia'}</span>
            </th>
          </tr>
        </thead>
      )}
      {processedGlobalScoreStats ? (
        // Data
        <tbody className="bx-global-scores-table__body">
          {processedGlobalScoreStats.map((scoreStat, index, scoreStats) => (
            <tr key={index} className="bx-global-scores-table__row">
              <td className="bx-global-scores-table__data-cell bx-global-scores-table__data-cell--position">
                {index && scoreStats.at(index - 1)?.position === scoreStat.position ? null : scoreStat.position + 1}
              </td>
              <td className="bx-global-scores-table__data-cell bx-global-scores-table__data-cell--nickname">{scoreStat.player.nickName}</td>
              <td className="bx-global-scores-table__data-cell bx-global-scores-table__data-cell--max">{scoreStat.max.toFixed()}</td>
              <td className="bx-global-scores-table__data-cell bx-global-scores-table__data-cell--avg">{scoreStat.avg.toFixed()}</td>
            </tr>
          ))}
        </tbody>
      ) : (
        // Loading indicator
        <tbody className="bx-global-scores-table__message-container">
          <tr>
            <td colSpan={4}>
              <p>{'Wczytywanie danych…'}</p>
            </td>
          </tr>
        </tbody>
      )}
      {/** Low item count indicator */}
      {processedGlobalScoreStats && processedGlobalScoreStats.length < 10 && (
        <tfoot className="bx-global-scores-table__message-foot bx-global-scores-table__message-container">
          <tr>
            <td colSpan={4}>
              <p className="bx-text-uppercase">{'nowy miesiąc = nowy ranking'}</p>
              <p>{'zagraj, żeby zająć miejsce w rankingu'}</p>
            </td>
          </tr>
        </tfoot>
      )}
    </table>
  )
}

/**
 * Create GlobalScores component with given filters
 */
export function withGameScoresProps<P extends ItemContentProps>(
  WrappedComponent: ComponentType<ComponentParams>,
  gameScoresProps: GameScoresProps
): ComponentType<P> {
  const Wrapped: ComponentType<P> = (props) => <GlobalScores {...props} {...gameScoresProps} />

  Wrapped.displayName = `withGameScoresProps(${WrappedComponent.displayName ?? WrappedComponent.name ?? 'Component'})`

  return Wrapped
}
