import type { JSX } from 'preact'
import type { FunctionComponent } from 'preact'
import { useState, useEffect } from 'preact/hooks'

import { AUDIO } from '../../constants/Audio.ts'
import { useAudioMixer } from '../../components/AudioMixer/AudioMixer.tsx'
import { useSystemStore } from '../../store/systemStore.ts'

import './Configuration.css'

/**
 * Configuration Page
 */
export const Configuration: FunctionComponent = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const playAudio = useAudioMixer()

  const serialNumber = useSystemStore((state) => state.serialNumber)
  const setAccessToken = useSystemStore((state) => state.setAccessToken)

  // Play background music
  useEffect(() => {
    const bgMusicPromise = playAudio(AUDIO.PAGE_CONFIGURATION, { loop: true })

    return () => void bgMusicPromise.then((audio) => audio?.stop())
  }, [playAudio])

  // eslint-disable-next-line @typescript-eslint/no-misused-promises
  const handleSubmit: JSX.GenericEventHandler<HTMLFormElement> = async (submitEvent) => {
    submitEvent.preventDefault()

    if (submitEvent.target instanceof HTMLFormElement) {
      const formData = new FormData(submitEvent.target)

      const boxnetId = formData.get('boxnetId')
      const password = formData.get('password')

      if (!boxnetId || !password) {
        return
      }

      setErrorMessage(null)
      setIsLoading(true)

      let response: Response

      // Basic auth credentials
      const credentials = btoa(`operator:${password}`)

      // Note: On success, the upcoming machine/paired event will trigger redirect
      try {
        response = await fetch(`${import.meta.env.VITE_API_URL}/terminal/${serialNumber}/configuration`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Basic ${credentials}`,
          },
          body: JSON.stringify({ machine: boxnetId }),
        })
      } catch (typeError) {
        return setErrorMessage('Brak połączenia z API')
      } finally {
        setIsLoading(false)
      }

      if (!response.ok) {
        return setErrorMessage('Nie można powiązać urządzeń')
      }

      const { accessToken } = (await response.json()) as { accessToken: string }

      setAccessToken(accessToken)
    }
  }

  // Form elements tabIndex
  let tabIndex = 0

  return (
    <section className="bx-page bx-page-configuration">
      <h2 className="bx-page-title">{'Konfiguracja'}</h2>
      <form autoComplete="off" onSubmit={handleSubmit}>
        <p>
          {'Numer seryjny'}: <code>{serialNumber}</code>
        </p>

        <fieldset className="bx-form-fieldset" disabled={isLoading}>
          <div className="bx-form-grid">
            <label className="bx-form-label" htmlFor="boxnetId">
              {'Boxnet ID'}
            </label>
            <input
              id="boxnetId"
              className="bx-form-input"
              autoFocus
              name="boxnetId"
              type="text"
              autoComplete="off"
              inputMode="numeric"
              size={9}
              pattern="\d{9}"
              required
              placeholder="000510001"
              tabIndex={++tabIndex}
            />

            <label className="bx-form-label" htmlFor="password">
              {'Hasło'}
            </label>
            <input
              id="password"
              className="bx-form-input bx-form-input--password"
              name="password"
              type="password"
              autoComplete="one-time-code"
              required
              placeholder="123"
              tabIndex={++tabIndex}
            />
          </div>

          <button className="bx-form-button" type="submit" tabIndex={++tabIndex}>
            {'Wyślij'}
          </button>
        </fieldset>

        <div className="bx-form-error" hidden={!errorMessage}>
          {'Błąd'}: {errorMessage}
        </div>
      </form>
    </section>
  )
}
