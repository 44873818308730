import type { FunctionComponent } from 'preact'

import { useGameStore } from '../../store/gameStore.ts'
import { QrCode } from '../../components/QrCode/QrCode.tsx'
import { GamePanels } from '../../components/GamePanels/GamePanels.tsx'

import './SessionStart.css'

export const SessionStart: FunctionComponent = () => {
  const sessionToken = useGameStore((state) => state.sessionToken)
  const player = useGameStore((state) => state.player)

  return (
    <section className="bx-page bg-page--session-start">
      {player === null ? (
        <>
          <p className="bx-session-start__text">
            {'Zaloguj się lub naciśnij'}
            <br />
            <em className="bx-text-emphasis">{'START'}</em>
            <br />
            {'aby rozpocząć grę.'}
          </p>

          <QrCode value={`${import.meta.env.VITE_PLAYER_URL}/session?token=${sessionToken}`} />

          <p className="bx-session-start__text bx-text-emphasis">{'Pobierz aplikację!'}</p>

          <p className="bx-session-start__text bx-color-primary">zyskaj</p>

          <p className="bx-session-start__text bx-color-primary">
            <em className="bx-text-emphasis">2x</em> więcej <em className="bx-text-emphasis">kredytów</em>
            <br />
            <em className="bx-text-emphasis">Ranking</em> z wynikami
            <br />
            <em className="bx-text-emphasis">Rywalizuj</em> z innymi
          </p>

          <p>
            {'PIN: '}
            {sessionToken}
          </p>
        </>
      ) : (
        <>
          <h2 className="bx-action-heading bx-action-heading--small">
            <span className="bx-animation bx-animation--slide-left">{'Hello'},</span>
            <span className="bx-animation bx-animation--slide-right">{player.nickName}</span>
          </h2>

          <p className="bx-animation bx-animation--fade-in bx-session-start__text">
            Aby rozpocząć grę naciśnij
            <br />
            <em className="bx-text-emphasis">START</em>
          </p>
        </>
      )}

      <GamePanels />
    </section>
  )
}
