import { render } from 'preact'
import { App } from './App.tsx'

import './assets/fonts/Tank/tank.css'
import './assets/fonts/Montserrat/montserrat.css'

import './index.css'

const startUrl = new URL(document.location.href)
const rootElement = document.getElementById('root') as HTMLDivElement

// Initialize mock responses
if (import.meta.env.MODE === 'development') {
  const { worker } = await import('./mocks/browser.ts')

  // Warn about unhandled requests to API, ignore rest (assets, chrome-extension)
  await worker.start({
    onUnhandledRequest(request, print) {
      if (request.url.startsWith(import.meta.env.VITE_API_URL)) {
        print.warning()
      }
    },
  })

  if (import.meta.env.VITE_API_DISABLE_MOCK_SW === 'true') {
    worker.stop()
  }
}

render(<App startUrl={startUrl} env={import.meta.env} />, rootElement)

/**
 * Unmount on location reload or assign (same origin)
 * @see https://developers.google.com/web/updates/2018/07/page-lifecycle-api
 */
window.addEventListener('pagehide', () => render(null, rootElement), false)
