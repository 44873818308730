import { get, set, del, createStore } from 'idb-keyval'
import type { PersistStorage, StorageValue } from 'zustand/middleware'
import { shallow } from 'zustand/shallow'

/**
 * IDB Storage without using JSON methods (parse, stringify)
 * Based on https://github.com/pmndrs/zustand/blob/v4.4.1/src/middleware/persist.ts#L31
 * @link https://docs.pmnd.rs/zustand/integrations/persisting-store-data#how-can-i-use-a-custom-storage-engine
 */
export function createIdbStorage<S>(
  /** Database name for exclusive use by state */
  dbName = 'keyval-store',
  /** Store name */
  storeName = 'keyval'
): PersistStorage<S> {
  const store = createStore(dbName, storeName)

  // Remember last state to minimize saves during power-offs
  let lastSetStorageValue: StorageValue<S> | undefined = undefined

  return {
    getItem: async (name) => (lastSetStorageValue = await get(name, store)) ?? null,

    setItem: (name, value) => {
      // Skip when no changes detected (alternative: JSON.stringify)
      if (lastSetStorageValue && lastSetStorageValue.version === value.version && shallow(lastSetStorageValue.state, value.state)) {
        return
      }

      return set(name, (lastSetStorageValue = value), store)
    },

    removeItem: (name) => {
      lastSetStorageValue = undefined

      return del(name, store)
    },
  }
}
